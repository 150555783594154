'use client'

import {
  Box,
  VStack,
  Button,
  Flex,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
  Link,
} from '@chakra-ui/react'

interface FeatureProps {
  heading: string
  text: string
}

const Feature = ({ heading, text }: FeatureProps) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  )
}

export default function GridListWithCTA() {
  return (
    <Box as={Container} maxW="7xl" mt={14} p={4}>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap={4}>

        <GridItem>
          <VStack alignItems={"flex-start"}>
            <chakra.p>
              Semua pengerjaan dilakukan oleh tim profesional yang sudah berpengalaman.
            </chakra.p>
            <chakra.p>
              apabila ada pertanyaan, silahkan hubungi kami dengan menekan tombol Chat WA.
            </chakra.p>
          </VStack>
        </GridItem>
        <GridItem colSpan={1}>
          <VStack alignItems="flex-end" spacing="20px">
            <chakra.h2 fontSize="3xl" fontWeight="700">
              Dimas Alfianda
            </chakra.h2>
            <Button as={Link} href='https://wa.me/6283857210996?text=Halo%2C%20saya%20ingin%20bertanya%20tentang%20LastMatch' colorScheme="green" size="md">
              Chat WA
            </Button>
          </VStack>
        </GridItem>
      </Grid>
      <Divider mt={12} mb={12} />
      {/* <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
        gap={{ base: '8', sm: '12', md: '16' }}>
        <Feature
          heading={'First Feature'}
          text={'Short text describing one of you features/service'}
        />
        <Feature
          heading={'Second Feature'}
          text={'Short text describing one of you features/service'}
        />
        <Feature
          heading={'Third Feature'}
          text={'Short text describing one of you features/service'}
        />
        <Feature
          heading={'Fourth Feature'}
          text={'Short text describing one of you features/service'}
        />
      </Grid> */}
    </Box>
  )
}