import * as React from "react"
import {
  chakra,
  keyframes,
  ImageProps,
  forwardRef,
  usePrefersReducedMotion,
  AvatarProps,
  Avatar,
} from "@chakra-ui/react"
import logo from "./assets/logo512.png"

export const Logo = forwardRef<AvatarProps,"img">((props, ref) => {
  return (
    <Avatar size='md' src={logo} name="Last Match" />
  )
})
