import React from 'react'
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    theme,
  } from "@chakra-ui/react"
import { useRoutes } from 'react-router-dom';
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Logo } from "../Logo";
import {LandingPage} from '../pages/landing';

export default function AppRoutes() {    
  return useRoutes([
    {
      path: '/',
      element: LandingPage(),
    },
  ]);
}