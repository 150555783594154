import { 
    Link,
    Stack,
    Flex,
    Button,
    Text,
    VStack,
    useBreakpointValue
} from '@chakra-ui/react'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

export default function WithBackgroundImage() {
    const scOffset = -70;
    const scDuration = 650;
  return (
    <Flex
      w={'full'}
      h={'100vh'}
      backgroundImage={require('../../assets/baju.jpg')}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, blackAlpha.600)'}>
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
            Last Match : match your style and your expectations
          </Text>
          <Stack direction={'row'}>            
            <Link
                as={ScrollLink}
                spy={true} 
                smooth={true}
                duration={scDuration} 
                offset={scOffset}
                to={'features'}
                bg={'blue.400'}
                rounded={'md'}
                p={2}
                fontSize={'md'}
                fontWeight={500}
                _hover={{
                    textDecoration: 'none',
                    bg: 'blue.500'
                }}>
                Lihat lebih banyak
            </Link>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  )
}