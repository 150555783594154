import React from 'react';
import { 
    Box,
    Heading,
    Text,
    Button,
    VStack,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';
import NavBar from '../../components/NavBar';
import SplitWithImage from './features';
import WithBackgroundImage from './hero';
import {
  PhoneIcon,
  ChevronRightIcon
} from '@chakra-ui/icons';
import SmallWithSocial from './footer';
import GridListWithCTA from './cta';



export const LandingPage = () => {
    return (
        <VStack>
            <NavBar />
            <WithBackgroundImage/>
            <SplitWithImage
            key={"1feat"}
            tag='Clothing'
            title="Baju custom sesuai desainmu"
            description="Kamu bisa mendesain baju sesuai keinginanmu"
            image={require('../../assets/hero.jpg')}
            features={[
                {
                    text: 'Baju Casual Custom',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('yellow.100', 'yellow.900')
                },
                {
                    text: 'Jersey Esport',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('green.100', 'green.900')
                },
                {
                    text: 'Jaket Esport',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('purple.100', 'purple.900')
                }
            ]}
            />
            <SplitWithImage
            key={"2feat"}
            tag='PC Gaming'
            title="Jasa Rakit & Service PC"
            description="Kamu bisa rakit PC sesuai budget atau kebutuhanmu."
            image={require('../../assets/servispc.jpg')}
            features={[
                {
                    text: 'PC low budget',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('yellow.100', 'yellow.900')
                },
                {
                    text: 'PC kantor',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('green.100', 'green.900')
                },
                {
                    text: 'PC Gaming',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('purple.100', 'purple.900')
                }
            ]}
            />
            <SplitWithImage
            key={"3feat"}
            tag='Accessories'
            title="Custom accessories"
            description="lengkapi pc gamingmu dengan aksesoris custom"
            image={require('../../assets/mousepad.webp')}
            features={[
                {
                    text: 'Mousepad Custom',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('yellow.100', 'yellow.900')
                },
                {
                    text: 'Tumblr Custom',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('green.100', 'green.900')
                },
                {
                    text: 'Keychain Custom',
                    icon: <Icon as={ChevronRightIcon} w={5} h={5} />,
                    iconBg: useColorModeValue('purple.100', 'purple.900')
                }
            ]}
            />
            <GridListWithCTA />
            <SmallWithSocial />
        </VStack>
    );
};

export default LandingPage;